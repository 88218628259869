import { useUserStore } from '@/stores/user';

class Api {
    private xsrfToken: ReturnType<typeof useCookie>;
    private toast: ReturnType<typeof useToast>;
    private userStore: ReturnType<typeof useUserStore>;

    constructor() {
        this.xsrfToken = useCookie('XSRF-TOKEN');
        this.toast = useNuxtApp().$toast;
        this.userStore = useUserStore();
    }

    private async refreshCsrfToken(): Promise<void> {
        if (!this.xsrfToken.value) {
            await this.userStore.csrf();
        }
    }

    public async execute(endpoint: string, method: string, body?: any): Promise<any> {
        await this.refreshCsrfToken();

        try {
            const response = await this.userStore.apiFetch(endpoint, {
                method,
                body,
            });

            return response;
        } catch (error) {

            if (error?.response?.status === 419) {
                if (!this.userStore.csrf.retryAttempted) {
                    this.userStore.csrf.retryAttempted = true;
                    await this.userStore.csrf();
                    return this.execute(endpoint, method, body); // Retry the API call
                } else {
                    throw new Error('CSRF token is invalid. Retry failed.');
                }
            } else if (error?.response?.status === 401) {
                this.toast.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Your session has ended",
                    life: 3000,
                });

                localStorage.setItem('user', JSON.stringify({}));
                localStorage.setItem('loggedIn', 'false');
                localStorage.removeItem('loggedAt');
                
                window.location.replace('/login');
            } else if (error?.response?.status === 422) {
                let message = '';
                try {
                    const errors = error.response._data.errors; // Assuming the errors are sent in this format
                    if (errors) {
                        message = Object.keys(errors).map(field => {
                            // Assuming each field's errors are an array of strings
                            return `${field}: ${errors[field].join(', ')}`;
                        }).join('\n'); // Join all messages with a newline
                    } else {
                        message = error.response._data.message || 'Validation error';
                    }
                } catch (e) {
                    console.log(e)
                    message = "An error had occurred";
                }
                this.toast.add({
                    severity: "error",
                    summary: "Error",
                    detail: message,
                    life: 3000,
                });
            
                throw new Error('Validation error');
            } else if (error?.response?._data.error) {
                this.toast.add({
                    severity: "error",
                    summary: "Error",
                    detail: error?.response?._data.error,
                    life: 4000,
                });
            } else {
                this.toast.add({
                    severity: "error",
                    summary: "Error",
                    detail: "An error had occurred",
                    life: 3000,
                });

                throw new Error('Validation error');
            }

            return error
        }
    }
}

export default Api;